import axiosSettings from "../../api-config/axiosSettings";

export class ResourcesService {
  static async addResourcesDocument(document) {
    try {
      let formData = new FormData();
      formData.append("document", document);
      return await axiosSettings.post("/admin/resources/", formData);
    } catch (error) {
      throw Object(error);
    }
  }

  static async readResourceLink(name) {
    const data = { name };
    try {
      return await axiosSettings.post(`/admin/resources/read`, data);
    } catch (error) {
      throw Object(error);
    }
  }
}
