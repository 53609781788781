import React, { useEffect, useState } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import Searchable from "react-searchable-dropdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useLocale from "../../hooks/useLocale";
import { CountryEng, CountryTr } from "../../Authentication/Country";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ProfileService } from "../../services/profile";
import { NotifySuccess, NotifyError } from "../../Helpers/Toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { LocalStorageService } from "../../services/localStorage";

const UserProfileForm = () => {
  const navigate = useNavigate();
  const { locale } = useLocale();
  const { t } = useTranslation("translation");
  const currentRole = LocalStorageService.getRole();
  const profileData = useSelector((state) => state.userProfile);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (profileData?.profile) {
      setProfile(profileData.profile);
    }
  }, [profileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const transformedCountry = (locale === "tr" ? CountryTr : CountryEng).map(
    (country) => {
      return {
        label: country.name,
        value: country.name,
      };
    }
  );

  function getCurrentCountry() {
    const turkishCountry = CountryTr?.find((x) => x?.name === profile?.country);
    const englishCountry = CountryEng?.find(
      (x) => x?.name === profile?.country
    );
    if (turkishCountry) {
      return turkishCountry?.name;
    }
    return englishCountry?.name;
  }

  async function handleSubmit() {
    try {
      const res = await ProfileService.updateProfile(profile);
      if (res?.status) {
        NotifySuccess(t("User profile has been updated."));

        navigate(currentRole === "ROLE_SUPERVISOR" ? "/supervisor" : "/");
      }
    } catch (err) {
      NotifyError(err?.message);
    }
  }

  const handlePhone = (phoneNumber, data, type) => {
    if (
      data?.format?.match(/\./g).length === phoneNumber?.length &&
      !data.whatsapp
    ) {
      handleChange({
        target: {
          name: type,
          value: phoneNumber,
        },
      });
    }
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <ToastContainer />
      <Card style={{ width: "30rem" }}>
        <Card.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t("Email address")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("Email address")}
                name="email"
                disabled
                value={profile.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicFirstName">
              <Form.Label>{t("First Name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("First Name")}
                name="firstName"
                value={profile.firstName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicLastName">
              <Form.Label>{t("Last Name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Last Name")}
                name="lastName"
                value={profile.lastName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPhone">
              <Form.Label>{t("Phone")}</Form.Label>
              <PhoneInput
                country="tr"
                value={profile?.phone}
                preferredCountries={["tr"]}
                disableDropdown={false}
                onChange={(phoneNumber, data) => {
                  handlePhone(phoneNumber, data, "phone");
                }}
                containerStyle={{ width: "100%" }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicCountry">
              <Form.Label>{t("Country")}</Form.Label>
              {profile.city && profile?.country ? (
                <Searchable
                  className="form-control select2"
                  placeholder={t("Choose Country")}
                  notFoundText={t("No result found")}
                  noInput
                  options={transformedCountry}
                  value={getCurrentCountry()}
                  onSelect={(value) =>
                    handleChange({
                      target: {
                        name: "country",
                        value,
                      },
                    })
                  }
                  listMaxHeight={140}
                />
              ) : (
                <Searchable
                  className="form-control select2"
                  placeholder={t("Choose Country")}
                  notFoundText={t("No result found")}
                  noInput
                  options={transformedCountry}
                  value={getCurrentCountry()}
                  onSelect={(value) =>
                    handleChange({
                      target: {
                        name: "country",
                        value,
                      },
                    })
                  }
                  listMaxHeight={140}
                />
              )}
            </Form.Group>
            <Form.Group controlId="formBasicCity">
              <Form.Label>{t("City")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                value={profile.city}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicWhatsapp">
              <Form.Label>{t("WhatsApp")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="WhatsApp"
                name="whatsapp"
                value={profile.whatsapp}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="button" onClick={handleSubmit}>
              {t("Update Profile")}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default UserProfileForm;
