import axios from "axios";
import { LocalStorageService } from "../services/localStorage";

const instance = axios.create({
  // baseURL: "http://ec2-3-66-190-34.eu-central-1.compute.amazonaws.com/backend",
  baseURL: "https://gdnplatform.com/backend",
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

instance.interceptors.request.use(
  function (config) {
    const token = LocalStorageService.getAuth();
    const apiToken = !!token ? token : "";
    config.headers = {
      ...config.headers,
    };
    if (apiToken) {
      config.headers.Authorization = `Bearer ${apiToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    var errorObj = {
      response: { message: "" },
    };
    if (error.response === undefined || error.response.status === 405) {
      errorObj.response.message = error.message.toString();
      return Promise.reject(errorObj);
    } else if (error.response.status === 401) {
      if (error?.response?.config?.url === "/auth/login")
        return Promise.reject(error);
      LocalStorageService.clearLocalStorage();
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

export default instance;
