import React, { Fragment } from "react";
import { Container, Dropdown, InputGroup, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import { LocalStorageService } from "../../services/localStorage";
import { useSelector } from "react-redux";

export const TrFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <script
        xmlns=""
        id="argent-x-extension"
        data-extension-id="dlcobpjiigpikoobohmabehhmhfoodbb"
      />
      <g transform="translate(0 512) scale(1 -1)">
        <mask id="svgIDa">
          <circle cx="256" cy="256" r="256" fill="#fff" />
        </mask>
        <g mask="url(#svgIDa)">
          <path fill="#d80027" d="M0 0h512v512H0z" />
          <g fill="#eee">
            <path d="m245.5 209.2l21 29l34-11.1l-21 29l21 28.9l-34-11.1l-21 29V267l-34-11.1l34-11z" />
            <path d="M188.2 328.3a72.3 72.3 0 1 1 34.4-136a89 89 0 1 0 0 127.3a72 72 0 0 1-34.4 8.7z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const EnFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <circle cx="256" cy="256" r="256" fill="#f0f0f0" />
      <g fill="#0052b4">
        <path d="M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178L52.92 100.142zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076h133.176zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075H8.819zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177l89.076-89.075zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005l-89.076 89.074zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075V8.819zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075v133.176zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076H370.005z" />
      </g>
      <g fill="#d80027">
        <path d="M509.833 222.609H289.392V2.167A258.556 258.556 0 00256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 000 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0066.783 0V289.392h220.442A258.533 258.533 0 00512 256c0-11.317-.744-22.461-2.167-33.391z" />
        <path d="M322.783 322.784L437.019 437.02a256.636 256.636 0 0015.048-16.435l-97.802-97.802h-31.482v.001zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0016.435 15.048l97.802-97.804v-31.479zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 00-15.048 16.435l97.803 97.803h31.481zM322.783 189.219L437.02 74.981a256.328 256.328 0 00-16.435-15.047l-97.802 97.803v31.482z" />
      </g>
    </svg>
  );
};

// FuScreen-start
function Fullscreen() {
  if (
    (document.fullScreenElement && document.fullScreenElement === null) ||
    (!document.mozFullScreen && !document.webkitIsFullScreen)
  ) {
    if (document.documentElement.requestFullScreen) {
      document.documentElement.requestFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
      document.documentElement.webkitRequestFullScreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
}
// FullScreen-end
function Header({ offline }) {
  const { t } = useTranslation("translation");
  const { i18n } = useTranslation();
  const profileData = useSelector((state) => state.userProfile);
  let navigate = useNavigate();
  const routeChange = () => {
    localStorage.clear();
    let path = `${process.env.PUBLIC_URL}/`;
    navigate(path);
  };

  //  headerToggleButton
  const headerToggleButton = () => {
    let body = document.querySelector("body");
    let innerWidth = window.innerWidth;
    if (body !== !body) {
      if (innerWidth >= 992) {
        document.querySelector("body")?.classList.toggle("main-sidebar-hide");
        document.querySelector("body")?.classList.remove("main-sidebar-show");
      } else if (document.body.classList.contains("horizontalmenu")) {
        document.querySelector("body")?.classList.toggle("main-navbar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-hide");
      } else {
        document.querySelector("body")?.classList.toggle("main-sidebar-show");
        document.querySelector("body")?.classList.remove("main-sidebar-hide");
      }
    }
  };

  const Darkmode = () => {
    document.querySelector("body").classList.toggle("dark-theme");
  };

  const currentRole = LocalStorageService.getRole();

  const getUserRole = () => {
    if (currentRole === "ROLE_MENTOR") {
      return "Embarker";
    } else {
      return currentRole;
    }
  };

  const { locale, setLocale } = useLocale();

  const changeLang = (lang) => {
    LocalStorageService.setLang(lang);
    setLocale(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <Fragment>
      <Navbar expand="lg" className="main-header side-header sticky">
        <Container
          fluid
          className={`main-container container-fluid ${
            offline && "justify-content-end bg-transparent"
          }  `}
        >
          {!offline && (
            <>
              <div className="main-header-left">
                <Link
                  to="#"
                  className="main-header-menu-icon d-block d-sm-none mt-4"
                  id="mainSidebarToggle"
                  onClick={() => headerToggleButton()}
                >
                  <span></span>
                </Link>
                <div className="hor-logo">
                  <Link
                    to={`${process.env.PUBLIC_URL}/dashboard/`}
                    className="main-logo"
                  >
                    <img
                      src={require("../../assets/img/brand/logo.png")}
                      className="header-brand-img desktop-logo w-50"
                      alt="logo"
                    />
                    <img
                      src={require("../../assets/img/brand/logo-light.png")}
                      className="header-brand-img desktop-logo-dark"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="main-header-center">
                <div className="responsive-logo">
                  <Link to={`${process.env.PUBLIC_URL}/dashboard/`}>
                    <img
                      src={require("../../assets/img/brand/logo.png")}
                      className="mobile-logo"
                      style={{ maxWidth: 70 }}
                      alt="logo"
                    />
                  </Link>
                  <Link to={`${process.env.PUBLIC_URL}/dashboard/`}>
                    <img
                      src={require("../../assets/img/brand/logo-light.png")}
                      className="mobile-logo-dark"
                      alt="logo"
                    />
                  </Link>
                </div>
                <InputGroup></InputGroup>
              </div>
            </>
          )}

          <div className="main-header-right">
            <Navbar.Toggle
              aria-controls="navbarSupportedContent-4"
              className="navresponsive-toggler"
            >
              <i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
            </Navbar.Toggle>
            <div className="navbar navbar-expand-lg nav nav-item navbar-nav-right responsive-navbar navbar-dark">
              <Navbar.Collapse
                className="collapse navbar-collapse"
                id="navbarSupportedContent-4"
              >
                <div className="d-flex order-lg-2 align-items-center ms-auto">
                  {!offline && (
                    <Dropdown className="dropdown d-flex main-header-theme">
                      <Nav.Link
                        className="nav-link icon layout-setting"
                        onClick={() => Darkmode()}
                      >
                        <span className="dark-layout">
                          <i className="fe fe-sun header-icons"></i>
                        </span>
                        <span className="light-layout">
                          <i className="fe fe-moon header-icons"></i>
                        </span>
                      </Nav.Link>
                    </Dropdown>
                  )}

                  {/* Language Dropdown */}
                  <Dropdown className=" main-header-notification flag-dropdown">
                    <Dropdown.Toggle
                      className="nav-link icon country-Flag "
                      variant="default"
                    >
                      {locale === "tr" ? <TrFlag /> : <EnFlag />}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdown-menu"
                      style={{ margin: "0px" }}
                    >
                      <Dropdown.Item
                        className="d-flex"
                        onClick={() => changeLang("en")}
                      >
                        <span className="avatar  me-3 align-self-center bg-transparent">
                          <EnFlag />
                        </span>
                        <div className="d-flex">
                          <span className="mt-2">English</span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="d-flex "
                        onClick={() => changeLang("tr")}
                      >
                        <span className="avatar  me-3 align-self-center bg-transparent">
                          <TrFlag />
                        </span>
                        <div className="d-flex">
                          <span className="mt-2">Turkish</span>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {!offline && (
                    <>
                      <div className="d-md-flex">
                        <div
                          className="nav-link icon full-screen-link"
                          onClick={Fullscreen}
                        >
                          <i className="fe fe-maximize fullscreen-button fullscreen header-icons"></i>
                          <i className="fe fe-minimize fullscreen-button exit-fullscreen header-icons"></i>
                        </div>
                      </div>
                      <Dropdown className="main-profile-menu">
                        <Dropdown.Toggle
                          className="d-flex p-0"
                          variant="default"
                        >
                          <span className="main-img-user mx-1">
                            <img
                              alt="avatar"
                              src={require("../../assets/img/users/1.jpg")}
                            />
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ margin: "0px" }}>
                          <div className="header-navheading">
                            <p className="main-notification-text fw-bold">
                              {profileData?.profile?.firstName
                                ? `${profileData?.profile?.firstName} ${profileData?.profile?.lastName}`
                                : getUserRole()}
                            </p>
                          </div>
                          <Dropdown.Item
                            onClick={() => {
                              let path = `${process.env.PUBLIC_URL}/profile`;
                              navigate(path);
                            }}
                          >
                            <i className="fe fe-user"></i> {t("Edit Profile")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              routeChange();
                            }}
                          >
                            <i className="fe fe-power"></i> {t("Sign Out")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                </div>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </Fragment>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
