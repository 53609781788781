import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "./index.css";

export function ProjectsTable({ projects, onProjectSelected, onDelete }) {
  const { t } = useTranslation("translation");

  const handleDelete = (id) => {
    if (window.confirm(`Are you sure you want to delete?`)) {
      onDelete(id);
    }
  };

  const columns = [
    {
      name: t("Id"),
      selector: (row) => [row.id],
      sortable: true,
      cell: (row) => <div className="font-weight-bold">{row.id}</div>,
    },
    {
      name: t("Project Name"),
      selector: (row) => [row.projectName],
      sortable: true,
      cell: (row) => <div>{row.projectName}</div>,
    },
    {
      name: (
        <div className="d-flex justify-content-center w-100">
          {t("ACTIONS")}
        </div>
      ),
      selector: (row) => [row.ACTIONS],
      sortable: true,
      cell: (row) => (
        <div className="d-flex justify-content-center w-100 gap-3">
          <button
            className="btn btn-sm btn-primary p-1"
            onClick={() => handleDelete(row?.id)}
          >
            {t("Delete")}
          </button>
          <button
            className="btn btn-sm btn-secondary p-1"
            onClick={() => onProjectSelected(row?.id)}
          >
            {t("Edit")}
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: projects,
  };

  return (
    <DataTableExtensions {...tableData}>
      <DataTable
        className="overflow-visible"
        columns={columns}
        defaultSortAsc={false}
        pagination
      />
    </DataTableExtensions>
  );
}
