import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";
import Auth from "./Authentication/auth";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import { learningSpaceMockData } from "./features/learningSpaces/mockdata";
import UserLearningSpaceSetupDashboard from "./features/learningSpaces/UserDashboard";
import CalenderPage from "./features/calender";
import { LocaleProvider } from "./hooks/localization/context";
import "../src/i18n";
import ReverseMentorship from "./features/reverseMentorship";
import ProgramProgressPage from "./features/programProgressPage";
import ProjectsList from "./features/projects";
import AdminUsersList from "./features/adminUsers";
import SupervisorDashboard from "./features/supervisor/SupervisorDashboard";
import UserProfileForm from "./features/profile";

// Dashboard
const App = React.lazy(() => import("./components/app"));
// AdvanceUi
const Themepage = React.lazy(() => import("./components/Themepage"));
const MessageDanger = React.lazy(() =>
  import("./components/Pages/MessageDanger/MessageDanger")
);
const MessageWarning = React.lazy(() =>
  import("./components/Pages/MessageWarning/MessageWarning")
);
const Messagesuccess = React.lazy(() =>
  import("./components/Pages/Messagesuccess/Messagesuccess")
);
// coustom pages
const Error505 = React.lazy(() =>
  import("./components/Custompages/Error-505/Error-505")
);
const Error404 = React.lazy(() =>
  import("./components/Custompages/Error1-404/Error-404")
);
const Signin = React.lazy(() =>
  import("./components/Custompages/Signin/Signin")
);
const Signup = React.lazy(() =>
  import("./components/Custompages/Signup/Signup")
);
const Lockscreen = React.lazy(() =>
  import("./components/Custompages/Lockscreen/Lockscreen")
);
const Resetpassword = React.lazy(() =>
  import("./components/Custompages/Resetpassword/Resetpassword")
);
const Forgotpassword = React.lazy(() =>
  import("./components/Custompages/Forgotpassword/Forgotpassword")
);
const Custompage = React.lazy(() => import("./components/Custompage"));
const Underconstructionpage = React.lazy(() =>
  import("./components/UnderConstruction")
);
const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"));
const ForgetPassword = React.lazy(() =>
  import("./Authentication/ForgetPassword")
);
const ProgramsList = React.lazy(() => import("./features/programs/List"));
const CreateProgram = React.lazy(() =>
  import("./features/programs/createProgram")
);

const CreateForm = React.lazy(() => import("./features/forms/CreateForm"));

const FormList = React.lazy(() => import("./features/forms/forms"));

const CreateAppointment = React.lazy(() =>
  import("./features/appointment/createAppointment")
);

const LearningSpaceSetupDashboard = React.lazy(() =>
  import("./features/learningSpaces/SetupDashboard")
);

const EmbarkDashboard = React.lazy(() => import("./features/dashboard"));

const Guidelines = React.lazy(() => import("./features/guidelines"));

const Root = () => {
  return (
    <Provider store={store}>
      <Fragment>
        <BrowserRouter>
          <LocaleProvider>
            <React.Suspense fallback={<Loader />}>
              <Routes>
                <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
                  <Route index element={<AuthLogin />} />
                  <Route
                    path={`${process.env.PUBLIC_URL}/authentication/login`}
                    element={<AuthLogin />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/authentication/signup`}
                    element={<AuthSignup />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/authentication/ForgetPassword`}
                    element={<ForgetPassword />}
                  />
                </Route>

                <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
                  {/* Embark Programs */}
                  <Route>
                    <Route
                      path={`${process.env.PUBLIC_URL}/programs/list`}
                      element={<ProgramsList />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/forms`}
                      element={<FormList />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/projects`}
                      element={<ProjectsList />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/admin-users`}
                      element={<AdminUsersList />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/supervisor`}
                      element={<SupervisorDashboard />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/forms/newform/:formId`}
                      element={<CreateForm />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/programs/learningspaces/:programId`}
                      element={
                        <LearningSpaceSetupDashboard
                          learningSpace={learningSpaceMockData}
                        />
                      }
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/profile`}
                      element={<UserProfileForm />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/mentor/learningspaces`}
                      element={
                        <UserLearningSpaceSetupDashboard
                          learningSpace={learningSpaceMockData}
                        />
                      }
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/programs/create`}
                      element={<CreateProgram />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/programs/create-isReversed`}
                      element={<CreateProgram />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/programs/create/:programId`}
                      element={<CreateProgram />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/programs/create-isReversed/:programId`}
                      element={<CreateProgram />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/reverseMentorship`}
                      element={<ReverseMentorship />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/appointment/create`}
                      element={<CreateAppointment />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/appointment/create/:appointmentId`}
                      element={<CreateAppointment />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/gelecekdahanet/:programId`}
                      element={<EmbarkDashboard />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/programProgress/:programId`}
                      element={<ProgramProgressPage />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/guidelines`}
                      element={<Guidelines />}
                    />

                    <Route
                      path={`${process.env.PUBLIC_URL}/gelecekdahanet/`}
                      element={<EmbarkDashboard />}
                    />
                    <Route
                      path={`${process.env.PUBLIC_URL}/calender/`}
                      element={<CalenderPage />}
                    />
                  </Route>
                </Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/switcherpages`}
                  element={<Themepage />}
                />
                {/* ........................................Custompage............................................... */}
                <Route
                  path={`${process.env.PUBLIC_URL}/`}
                  element={<Custompage />}
                >
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/messagesuccess`}
                    element={<Messagesuccess />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/messagewarning`}
                    element={<MessageWarning />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/messagedanger`}
                    element={<MessageDanger />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/custompages/error404`}
                    element={<Error404 />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/custompages/lockscreen`}
                    element={<Lockscreen />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/custompages/error505`}
                    element={<Error505 />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/custompages/forgotpassword`}
                    element={<Forgotpassword />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/custompages/resetpassword`}
                    element={<Resetpassword />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/custompages/signup`}
                    element={<Signup />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/custompages/signin`}
                    element={<Signin />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/custompages/underconstruction`}
                    element={<Underconstructionpage />}
                  />
                </Route>
                {/* ........................................Errorpage............................................... */}
                <Route path="*" element={<Error404 />} />
              </Routes>
            </React.Suspense>
          </LocaleProvider>
        </BrowserRouter>
      </Fragment>
    </Provider>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
