import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";

import { getProgress } from "../../redux/slices/progress";
import { LearningSpacesService } from "../../services/learningSpaces";
import { getCurrentProgress } from "../../redux/slices/currentProgress";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DetailModal from "./components/DetailModal";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AppointmentService } from "../../services/appointment";

const Wrapper = styled.div`
  margin-top: 2rem;
  position: relative;
  z-index: 1;
  @media (max-width: 992px) {
    margin-top: 10rem;
  }
`;

const localizer = momentLocalizer(moment);

function CalenderPage() {
  const { t, i18n } = useTranslation("translation");
  const dispatch = useDispatch();

  const isEnglish = i18n?.resolvedLanguage === "en";

  const { progress } = useSelector((state) => state.progress);
  const { currentProgress } = useSelector((state) => state.currentProgress);
  const [events, setEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [allAppointments, setAppointments] = useState([]);
  const [learningSpaceWeeks, setLearningSpaceWeeks] = useState([]);

  const handleEvents = () => {
    let currentEvents = [];

    learningSpaceWeeks?.forEach((item, idx) => {
      item?.learningSpaceWeekSections?.forEach((element) => {
        let event = {};
        event.title = `${element?.description} - ${
          isEnglish ? item?.titleEn : item?.titleTr
        } `;
        event.start = new Date(moment(element?.startDate));
        event.end = new Date(moment(element?.endDate));
        event.isOnline = element.isOnline;
        event.link = element?.meetingLink;
        currentEvents.push(event);
      });
    });
    allAppointments?.forEach((element, idx) => {
      const { end, location, start, title, isOffSite } = element;
      let newEvent = {
        title: `Seans  ${idx + 1}`,
        start: new Date(moment(start)),
        end: new Date(moment(end)),
        isOnline: !isOffSite,
        link: location,
        isAppointment: true,
      };
      currentEvents.push(newEvent);
    });
    setEvents(currentEvents);
  };
  
  async function handleLearningSpace() {
    try {
      await LearningSpacesService.getSpaces(progress?.programId).then((res) => {
        if (res?.data) {
          setLearningSpaceWeeks(res?.data?.learningSpaceWeeks);
        }
      });
    } catch (e) {
      console.log(e.message);
    }
  }

  async function getAppointment() {
    try {
      const res = await AppointmentService.getAppointment();
      if (res) {
        setAppointments(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (
      (learningSpaceWeeks && learningSpaceWeeks.length > 0) ||
      (allAppointments && allAppointments?.length > 0)
    ) {
      handleEvents();
    }
  }, [learningSpaceWeeks, allAppointments]);

  useEffect(() => {
    if (progress && progress?.programId) {
      handleLearningSpace();
    }
  }, [currentProgress]);

  useEffect(() => {
    dispatch(getProgress());
    dispatch(getCurrentProgress());
    getAppointment();
  }, []);

  function eventStyleGetter(event, start, end, isSelected) {
    let style = {};

    if (event?.isAppointment) {
      style = {
        backgroundColor: "#23cbf1",
        borderColor: "#23cbf1",
      };
    } else {
      style = {
        backgroundColor: "#f05e22",
        borderColor: "#f05e22",
      };
    }

    return {
      style: style,
    };
  }

  return (
    <Wrapper>
      <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800 }}
        events={events}
        onSelectEvent={(e) => {
          setSelectedEvent(e);
          setIsOpen(true);
        }}
        messages={{
          next: t("Next"),
          previous: t("Previous"),
          today: t("Today"),
          month: t("Month"),
          week: t("Week"),
          day: t("Day"),
          agenda: t("Agenda"),
        }}
        eventPropGetter={eventStyleGetter}
      />
      <DetailModal
        show={isOpen}
        selectedEvent={selectedEvent}
        handleClose={() => {
          setIsOpen(false);
          setSelectedEvent({});
        }}
      />
    </Wrapper>
  );
}

export default CalenderPage;
