import React, { Fragment, useState, useEffect } from "react";
import { Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProgress } from "../../redux/slices/progress";
import { ProgramService } from "../../services";
import { LocalStorageService } from "../../services/localStorage";
import { ResourcesService } from "../../services/resources";
import { NotifyError } from "../../Helpers";

const ReverseMentorship = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [programInfo, setProgramInfo] = useState(null);

  const currentLang = LocalStorageService.getLang();
  const { progress } = useSelector((state) => state.progress);

  useEffect(() => {
    dispatch(getProgress());
  }, []);

  async function getProgramDetails() {
    try {
      const res = await ProgramService.getProgramInfo(progress?.programId);
      if (res) {
        setProgramInfo(res?.data);
      }
    } catch (err) {
      setProgramInfo(null);
      console.log("error");
    }
  }

  useEffect(() => {
    if (progress && progress?.programId) {
      getProgramDetails();
    }
  }, [progress]);

  return (
    <Fragment>
      <div className="page-header">
        <div className="d-flex justify-content-center w-100">
          <h2 className="main-content-title tx-24 mg-b-5 text-center">
            {t("Welcome to the GDN Platform Reverse Mentoring Module")}!
          </h2>
        </div>
      </div>
      <Row>
        <div className="col-0 col-lg-2">
          <div className="w-100 d-flex flex-column justify-content-center gap-3 mb-3">
            <button
              className="btn border-primary text-primary"
              onClick={() => setIsOpen(true)}
            >
              {t("Download Menti Guide")}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                navigate("/appointment/create");
              }}
            >
              {t("Create Mentorship Appointment")}
            </button>
          </div>
        </div>
        <div className="col-12 col-xl-8">
          <div className="mb-3">
            <div className="d-flex align-items-center gap-2">
              <h6 className="m-0">{t("Project Name")}:</h6>
              <p className="m-0">{programInfo?.projectName}</p>
            </div>
            <div className="d-flex align-items-center gap-2">
              <h6 className="m-0">{t("Program Name")}:</h6>
              <p className="m-0">{programInfo?.programName}</p>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                currentLang === "tr"
                  ? programInfo?.trText
                  : programInfo?.enText,
            }}
          />
        </div>
        <div className="col-0 col-lg-2"></div>
      </Row>
      <Row></Row>
      <DownloadModal
        show={isOpen}
        programInfo={programInfo}
        onHide={() => setIsOpen(false)}
      />
    </Fragment>
  );
};

export default ReverseMentorship;

function DownloadModal(props) {
  const { t } = useTranslation("translation");

  async function getDocumentUrl(name) {
    try {
      const res = await ResourcesService.readResourceLink(name);
      if (res) {
        return res?.data?.url;
      }
    } catch (err) {
      NotifyError("Document Error");
    }
  }

  const getFile = async (fileName) => {
    const name = await getDocumentUrl(fileName);
    props.onHide();
    window.open(name);
  };

  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton onClick={props.onHide}></Modal.Header>
      <Modal.Body className="text-center pd-y-20 pd-x-20">
        {t("Which language do you want to download the document")}?
      </Modal.Body>
      <Modal.Footer>
        {props?.programInfo?.enDocument && (
          <a
            className="btn btn-sm btn-primary"
            onClick={() => {
              getFile(props?.programInfo?.enDocument);
            }}
          >
            {t("Download English Document")}
          </a>
        )}
        {props?.programInfo?.trDocument && (
          <a
            className="btn btn-sm btn-secondary"
            onClick={() => {
              getFile(props?.programInfo?.trDocument);
            }}
          >
            {t("Download Turkish Document")}
          </a>
        )}
      </Modal.Footer>
    </Modal>
  );
}
