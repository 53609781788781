import React from "react";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocaleContext } from "../../../../hooks/localization/context";
import { EmbarkLocationDetails } from "../../../../Helpers/infoDetails";

function RowItem({ title, data, online }) {
  return (
    <div className="d-flex align-items-center w-100 overflow-hidden">
      <div className="fw-bold fs-6" style={{ minWidth: "10rem" }}>
        {title}:{" "}
      </div>
      {online ? data : <div className="fw-bolder">{data}</div>}
    </div>
  );
}

function DetailModal({ show, selectedEvent, handleClose }) {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();

  const [locale] = useLocaleContext();
  moment.locale(locale);

  const title = selectedEvent?.isAppointment ? "Appointment" : "Event";

  return (
    <Modal show={show} onHide={handleClose} className="mt-8">
      <Modal.Header closeButton>
        <Modal.Title>{t(`${title} Details`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-2">
        <RowItem title={t(`${title} Name`)} data={selectedEvent?.title} />
        <RowItem
          title={t(`${title} Type`)}
          data={selectedEvent.isOnline ? t("Online Event") : t("Onsite Event")}
        />
        <RowItem
          title={
            selectedEvent.isOnline ? t(`${title} Link`) : t(`${title} Location`)
          }
          online
          data={
            selectedEvent.isOnline ? (
              <a
                className="cursor-pointer"
                href={selectedEvent?.link}
                target="_blank"
              >
                {selectedEvent?.link}
              </a>
            ) : (
              <div>
                {EmbarkLocationDetails} -{" "}
                <a
                  className="cursor-pointer"
                  href={selectedEvent?.link}
                  target="_blank"
                >
                  {t("Click for Google Maps")}.
                </a>{" "}
              </div>
            )
          }
        />
        <RowItem
          title={t("Start Date")}
          data={moment(selectedEvent.start).format("LLLL")}
        />
        <RowItem
          title={t("End Date")}
          data={moment(selectedEvent.end).format("LLLL")}
        />
      </Modal.Body>
      {!selectedEvent?.isAppointment && (
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleClose();
              navigate("/mentor/learningspaces/");
            }}
          >
            {t("Go To Learning Space")}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default DetailModal;
