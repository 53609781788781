import React, { useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "./index.css";
import { EnFlag, TrFlag } from "../../../../layouts/Header/Header";
import { useTranslation } from "react-i18next";

function FormParserModal({ isOpen, data, formLanguages, onClose, onSubmit }) {
  const { t } = useTranslation("translation");
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({ arrayTOS: [], answersTOS: [] });
  const [arrayRows, setArrayRows] = useState({});
  const [lang, setLang] = useState("tr");

  const formRef = useRef(null);

  const handleNext = () => {
    if (formRef.current.checkValidity()) {
      if (currentStep < data?.length - 1) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      formRef.current.reportValidity();
    }
  };

  const handlePrevious = () => {
    if (formRef.current.checkValidity()) {
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
      }
    } else {
      formRef.current.reportValidity();
    }
  };

  const processFormData = (data) => {
    const processedData = {};

    for (let key in data) {
      // Split the key into parts by underscore
      const parts = key.split("_");

      // If the key contains more than one part, process it
      if (parts.length > 1) {
        // Form the fieldName by joining all parts except the last one
        const fieldName = parts.slice(0, -1).join("_");
        const rowIndex = parseInt(parts[parts.length - 1], 10);

        // If fieldName is not in processedData, initialize it
        if (!processedData[fieldName]) {
          processedData[fieldName] = [];
        }

        // If the array at processedData[fieldName] does not have an object at rowIndex, initialize it
        if (!processedData[fieldName][rowIndex]) {
          processedData[fieldName][rowIndex] = {};
        }

        // Set the value in the correct place in the processedData
        processedData[fieldName][rowIndex][key] = data[key];
      } else {
        // If the key does not contain underscore, just copy the value to processedData
        processedData[key] = data[key];
      }
    }

    return processedData;
  };

  const getCheckboxValues = (name) => {
    if (name?.includes("__")) {
      const nameArr = name.split("__");
      const arrayQuestionId = nameArr[0];
      const currentQuestionId = nameArr[1];
      const arrayIndex = nameArr[2];
      const item = formData?.arrayTOS
        ?.find((x) => x?.arrayQuestionId === arrayQuestionId)
        ?.allResponses?.find((x) => x?.arrayIndex === arrayIndex)
        ?.formResponseAnswersTOS?.find(
          (x) => x?.questionId === currentQuestionId
        );
      if (item) return item?.answers;
      return [];
    } else {
      const question = formData?.answersTOS?.find(
        (x) => x?.questionId === name
      );
      if (question) return question?.answers;
      return [];
    }
  };

  const getCurrentAnswer = (e) => {
    const { value, type, checked, name } = e.target;
    let answers = [];
    let answer = null;
    let answerId = null;
    if (type === "checkbox") {
      let values = getCheckboxValues(name) || [];
      if (checked) {
        values.push(value);
      } else {
        values = values.filter((val) => val !== value);
      }
      answers = [...values];
    } else {
      if (type === "text" || type === "date") {
        answer = value;
        answerId = null;
      } else {
        answer = null;
        answerId = value;
      }
    }
    return { answer, answerId, answers };
  };

  const handleArrayChanges = (e) => {
    const { name } = e.target;
    const newFormData = { ...formData };
    const nameArr = name.split("__");
    const arrayQuestionId = nameArr[0];
    const currentQuestionId = nameArr[1];
    const arrayIndex = nameArr[2];

    let currentArrayQuestionAnswers = newFormData?.arrayTOS?.find(
      (x) => x?.arrayQuestionId === arrayQuestionId
    );

    if (!currentArrayQuestionAnswers) {
      currentArrayQuestionAnswers = { arrayQuestionId, allResponses: [] };
    }

    let currentIndxRow = currentArrayQuestionAnswers?.allResponses?.find(
      (x) => x?.arrayIndex === arrayIndex
    );

    if (!currentIndxRow) {
      currentIndxRow = {
        arrayIndex,
        formResponseAnswersTOS: [],
      };
      currentArrayQuestionAnswers.allResponses.push({ ...currentIndxRow });
    }

    const currentQuestion = currentIndxRow?.formResponseAnswersTOS?.find(
      (x) => x?.questionId === currentQuestionId
    );

    if (!currentQuestion) {
      currentIndxRow.formResponseAnswersTOS.push({
        questionId: currentQuestionId,
      });
      const idx = currentArrayQuestionAnswers.allResponses.findIndex(
        (x) => x?.arrayIndex === arrayIndex
      );
      if (idx > -1) {
        currentArrayQuestionAnswers.allResponses[idx] = { ...currentIndxRow };
      }
    }

    const { answer, answerId, answers } = getCurrentAnswer(e);
    currentArrayQuestionAnswers.allResponses
      .find((x) => x.arrayIndex === arrayIndex)
      .formResponseAnswersTOS.find(
        (x) => x.questionId === currentQuestionId
      ).answer = answer;
    currentArrayQuestionAnswers.allResponses
      .find((x) => x.arrayIndex === arrayIndex)
      .formResponseAnswersTOS.find(
        (x) => x.questionId === currentQuestionId
      ).answerId = answerId;

    currentArrayQuestionAnswers.allResponses
      .find((x) => x.arrayIndex === arrayIndex)
      .formResponseAnswersTOS.find(
        (x) => x.questionId === currentQuestionId
      ).answers = answers;

    const idx = newFormData.arrayTOS.findIndex(
      (x) => x?.arrayQuestionId === arrayQuestionId
    );
    if (idx > -1) {
      newFormData.arrayTOS[idx] = { ...currentArrayQuestionAnswers };
    } else {
      newFormData.arrayTOS.push({ ...currentArrayQuestionAnswers });
    }

    setFormData(newFormData);
  };

  const handleChange = (e) => {
    const { name } = e.target;
    const isItArray = name?.indexOf("__") > -1;
    if (isItArray) {
      handleArrayChanges(e);
    } else {
      const { answer, answerId, answers } = getCurrentAnswer(e);
      const newFormData = { ...formData };
      const currentIdx = newFormData?.answersTOS?.findIndex(
        (x) => x?.questionId === name
      );
      if (currentIdx > -1) {
        newFormData.answersTOS[currentIdx].answer = answer;
        newFormData.answersTOS[currentIdx].answerId = answerId;
        newFormData.answersTOS[currentIdx].answers = answers;
      } else {
        newFormData.answersTOS.push({
          questionId: name,
          answer,
          answers,
          answerId,
        });
      }
      setFormData(newFormData);
    }
  };

  const handleSubmit = () => {
    if (formRef.current.checkValidity()) {
      const processedData = processFormData(formData);
      onSubmit(processedData);
    } else {
      formRef.current.reportValidity();
    }
  };

  const handleAddArrayRow = (id) => {
    setArrayRows((prev) => ({
      ...prev,
      [id]: (prev[id] || 1) + 1,
    }));
  };

  const handleDeleteArrayRow = (id, rowIndex) => {
    setArrayRows((prev) => {
      const currentCount = prev[id] || 1;
      if (currentCount > 1) {
        const newData = { ...formData };
        const newArr = newData?.arrayTOS
          ?.find((x) => x?.arrayQuestionId === id)
          ?.allResponses?.filter((x) => x?.arrayIndex !== rowIndex?.toString());
        const idx = newData?.arrayTOS?.findIndex(
          (x) => x?.arrayQuestionId === id
        );

        if (idx > -1) {
          newData.arrayTOS[idx].allResponses = [...newArr];
        }
        newData.arrayTOS = newData?.arrayTOS.map((arrayItem) => ({
          ...arrayItem,
          allResponses: arrayItem?.allResponses.map((response, index) => ({
            ...response,
            arrayIndex: String(index),
          })),
        }));
        setFormData(newData);

        return {
          ...prev,
          [id]: currentCount - 1,
        };
      } else {
        return prev;
      }
    });
  };

  const getLabel = (child) => {
    if (child?.labelEn && lang === "en")
      return <div dangerouslySetInnerHTML={{ __html: child?.labelEn }} />;
    if (child?.labelTr && lang === "tr")
      return <div dangerouslySetInnerHTML={{ __html: child?.labelTr }} />;
    return "Enter the label for this component";
  };

  const getPlaceholder = (child) =>
    lang === "tr" ? child?.hintTr : child?.hintEn;

  const getSectionName = (child) =>
    lang === "tr" ? child?.displayNameTr : child?.displayNameEn;

  const renderItem = (child, arrayId, rowIndex) => {
    const isInsideArray = arrayId && rowIndex !== undefined;

    const getName = () =>
      isInsideArray ? `${arrayId}__${child?.id}__${rowIndex}` : `${child?.id}`;

    const isCheckboxRequired = (isRequired, value) => {
      if (!isRequired) return false;
      else return value?.length === 0;
    };

    const getValue = () => {
      if (!isInsideArray) {
        const name = getName();
        const currentItem = formData.answersTOS?.find(
          (x) => x?.questionId === name
        );
        if (currentItem)
          return (
            currentItem?.answerId || currentItem?.answer || currentItem?.answers
          );
        return "";
      } else {
        const name = getName();
        const nameArr = name.split("__");
        const arrayQuestionId = nameArr[0];
        const currentQuestionId = nameArr[1];
        const arrayIndex = nameArr[2];

        const ques = formData?.arrayTOS
          .find((x) => x?.arrayQuestionId === arrayQuestionId)
          ?.allResponses?.find((x) => x?.arrayIndex === arrayIndex)
          ?.formResponseAnswersTOS?.find(
            (x) => x?.questionId === currentQuestionId
          );

        if (ques) return ques?.answerId || ques?.answer || ques?.answers;
      }
    };

    switch (child?.type) {
      case "section":
        return (
          <p style={{ fontSize: 18, fontWeight: "bold" }}>
            {getSectionName(child)}
          </p>
        );
      case "textBox":
        return (
          <Form.Group key={child.id}>
            {getLabel(child)}
            <Form.Control
              type={child?.questionFormat || ""}
              placeholder={getPlaceholder(child)}
              name={getName()}
              required={child.required}
              onChange={handleChange}
              value={getValue()}
            />
          </Form.Group>
        );
      case "radio":
        return (
          <Form.Group key={child.id}>
            {getLabel(child)}
            {child?.options?.map((option, index) => (
              <Form.Check
                key={index}
                type="radio"
                label={lang === "tr" ? option?.optionTr : option?.optionEn}
                value={option?.id}
                name={getName()}
                required={child?.required}
                onChange={handleChange}
                checked={getValue() === option?.id}
              />
            ))}
          </Form.Group>
        );
      case "dropDown":
        return (
          <Form.Group key={child.id}>
            {getLabel(child)}
            <Form.Select
              name={getName()}
              required={child.required}
              onChange={handleChange}
              value={getValue()}
            >
              <option value="">{getPlaceholder(child)}</option>
              {child?.options?.map((option, index) => (
                <option key={index} value={option?.id}>
                  {lang === "tr" ? option?.optionTr : option?.optionEn}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        );
      case "checkbox":
        return (
          <Form.Group key={child.id}>
            {getLabel(child)}
            {child?.options?.map((option) => (
              <Form.Check
                key={option?.id}
                type="checkbox"
                label={lang === "tr" ? option?.optionTr : option?.optionEn}
                value={option?.id}
                name={getName()}
                required={isCheckboxRequired(child.required, getValue())}
                onChange={handleChange}
                checked={getValue()?.includes(option?.id)}
              />
            ))}
          </Form.Group>
        );
      case "datepicker":
        return (
          <Form.Group key={child.id}>
            {getLabel(child)}
            <Form.Control
              type="date"
              placeholder={getPlaceholder(child)}
              format={child.questionFormat || "yyyy-MM-dd"}
              name={getName()}
              required={child.required}
              onChange={handleChange}
              value={getValue()}
            />
          </Form.Group>
        );
      case "INFO_TEXT":
        return <>{getLabel(child)}</>;
      case "array":
        return (
          <>
            {[...Array(arrayRows[child.id] || 1)]?.map((_, rowIndex) => (
              <div key={rowIndex} className="row mb-3">
                <div className="col-11">
                  {child?.children?.map((subChild) =>
                    renderItem(subChild, child.id, rowIndex)
                  )}
                </div>
                <div className="col-1 d-flex align-items-center">
                  {arrayRows[child.id] && arrayRows[child.id] > 1 && (
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteArrayRow(child.id, rowIndex)}
                    >
                      X
                    </Button>
                  )}
                </div>
              </div>
            ))}
            <Button onClick={() => handleAddArrayRow(child.id)}>Add</Button>
          </>
        );

      default:
        return null;
    }
  };

  const checkChildConditionAndRenderIt = (child) => {
    if (!child?.conditionAnswerId && !child?.conditionQuestionId) {
      return renderItem(child);
    } else if (child?.conditionAnswerId && child?.conditionQuestionId) {
      const condQuestion = formData?.answersTOS?.find(
        (x) => x?.questionId === child?.conditionQuestionId
      );
      if (condQuestion) {
        if (condQuestion?.answerId === child?.conditionAnswerId)
          return renderItem(child);
      }
    }
    return <></>;
  };

  return (
    <Modal show={isOpen}>
      <Modal.Header
        closeButton
        onClick={() => {
          onClose(false);
        }}
      >
        <Modal.Title>
          {lang === "en" ? "Please fill the form" : "Lütfen formu doldurun"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            justifyContent: "flex-end",
          }}
        >
          {formLanguages?.isTurkish && (
            <div style={{ width: 20 }} onClick={() => setLang("tr")}>
              <TrFlag />
            </div>
          )}
          {formLanguages?.isEnglish && (
            <div style={{ width: 20 }} onClick={() => setLang("en")}>
              <EnFlag />
            </div>
          )}
        </div>
        <Form ref={formRef}>
          {data && data[currentStep] && (
            <>
              {renderItem(data[currentStep])}
              {data[currentStep]?.children?.map((child) =>
                checkChildConditionAndRenderIt(child)
              )}
            </>
          )}

          <div className="d-flex justify-content-between">
            {currentStep > 0 && (
              <Button variant="secondary" onClick={handlePrevious}>
                {lang === "en" ? "Previous" : "Geri"}
              </Button>
            )}
            {currentStep < data?.length - 1 ? (
              <Button variant="primary" onClick={handleNext}>
                {lang === "en" ? "Next" : "İleri"}
              </Button>
            ) : (
              <Button variant="success" onClick={handleSubmit}>
                {lang === "en" ? "Submit" : "İlet"}
              </Button>
            )}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-gray" onClick={() => onClose(false)}>
          {lang === "en" ? "Cancel" : "İptal Et"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default FormParserModal;
