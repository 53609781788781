import axiosSettings from "../../api-config/axiosSettings";

export class ProjectsService {
  static async addProject(obj) {
    try {
      return await axiosSettings.post("/admin/project/", obj);
    } catch (error) {
      throw Object(error);
    }
  }

  static async getProjects() {
    try {
      return await axiosSettings.get("/admin/project/");
    } catch (error) {
      throw Object(error);
    }
  }

  static async getProjectById(id) {
    try {
      return await axiosSettings.get(`/admin/project/${id}`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async delete(id) {
    try {
      return await axiosSettings.delete(`/admin/project/${id}`);
    } catch (error) {
      throw Object(error);
    }
  }
}
