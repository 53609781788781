import React from "react";
import moment from "moment/moment";

import "moment/locale/tr";
import { remainingTime } from "../../../../../Helpers";
import { useTranslation } from "react-i18next";
import { useLocaleContext } from "../../../../../hooks/localization/context";
import { useSelector } from "react-redux";
import { EmbarkLocationDetails } from "../../../../../Helpers/infoDetails";
import styled from "styled-components";

function DetailRowSection({ title, details }) {
  return (
    <div className="d-flex">
      <div className="fw-bold" style={{ minWidth: "10rem" }}>
        {title}:
      </div>
      <div style={{ width: "100%", overflow: "hidden" }}>{details}</div>
    </div>
  );
}

function InterViewDetailCard() {
  const { t } = useTranslation("translation");
  const [locale] = useLocaleContext();
  const { learningSpace } = useSelector((state) => state);

  moment.locale(locale);

  const start = new Date()?.getTime();
  const end = new Date(learningSpace?.activeWeekSection?.endDate)?.getTime();
  const time = end - start;

  const handleResourceName = () => {
    let currentTitle = learningSpace?.activeSpace?.titleTr;
    if (currentTitle) {
      if (currentTitle?.split("-")[1]) {
        currentTitle = currentTitle?.split("-")[1];
      }
    }
    return currentTitle;
  };

  const handleSessionDetails = () => {
    if (learningSpace?.activeWeekSection?.isOnline) {
      return (
        <a href={learningSpace?.activeWeekSection?.meetingLink} target="_blank">
          {learningSpace?.activeWeekSection?.meetingLink}
        </a>
      );
    } else {
      return (
        <div>
          {EmbarkLocationDetails} -{" "}
          <a
            className="cursor-pointer"
            href={learningSpace?.activeWeekSection?.meetingLink}
            target="_blank"
          >
            {t("Click for Google Maps")}.
          </a>{" "}
        </div>
      );
    }
  };

  return (
    <>
      {time > 0 ? (
        <Wrapper className="card mb-3">
          <div className="card-body">
            <div className="d-flex flex-column border-bottom mb-3 gap-2 pb-3">
              <DetailRowSection
                title={t("Session Name")}
                details={
                  <>
                    {learningSpace?.activeWeekSection?.description}
                    {(learningSpace?.activeResource?.nameTr ||
                      learningSpace?.activeResource?.nameEn) &&
                      " - "}
                    {handleResourceName()}
                    {/* {locale === "tr"
                      ? learningSpace?.activeResource?.nameTr
                      : learningSpace?.activeResource?.nameEn} */}
                  </>
                }
              />
              <DetailRowSection
                title={
                  learningSpace?.activeWeekSection?.isOnline
                    ? t("Session Link")
                    : t("Session Location")
                }
                details={handleSessionDetails()}
              />
              <DetailRowSection
                title={t("Event Type")}
                details={
                  learningSpace?.activeWeekSection?.isOnline
                    ? t("Online Event")
                    : t("Onsite Event")
                }
              />
            </div>
            <div className="d-flex flex-column gap-2">
              <DetailRowSection
                title={t("Remaining Time")}
                details={time && remainingTime(time)}
              />
              <DetailRowSection
                title={t("Start Date")}
                details={moment(
                  learningSpace?.activeWeekSection?.startDate
                ).format("LLLL")}
              />
              <DetailRowSection
                title={t("End Date")}
                details={moment(
                  learningSpace?.activeWeekSection?.endDate
                ).format("LLLL")}
              />
            </div>
          </div>
        </Wrapper>
      ) : (
        <div style={{ marginTop: "5rem" }} />
      )}
    </>
  );
}

export default InterViewDetailCard;

const Wrapper = styled.div`
  margin-top: 4rem;
  @media (max-width: 768px) {
    margin-top: 13rem;
  }
`;
