import axiosSettings from "../../api-config/axiosSettings";

export class ProgramService {
  static async addProgram(program) {
    try {
      return await axiosSettings.post("/admin/program/", program);
    } catch (error) {
      throw Object(error);
    }
  }

  static async deleteProgram(id) {
    try {
      return await axiosSettings.delete(`/admin/program/${id}`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async getNotRegistered(id) {
    try {
      return await axiosSettings.get(
        `/admin/program/progress/${id}/notRegistered`
      );
    } catch (error) {
      throw Object(error);
    }
  }

  static async getPrograms() {
    try {
      return await axiosSettings.get("/admin/program/");
    } catch (error) {
      throw Object(error);
    }
  }

  static async getProgramById(id) {
    try {
      return await axiosSettings.get(`/admin/program/${id}`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async programProgress(id, sessionNumber, studentFormFilled) {
    try {
      let url = `/admin/program/progress/${id}/`;
      if (sessionNumber) {
        url = `/admin/program/progress/${id}?sessionNumber=${sessionNumber}`;
        if (studentFormFilled) {
          url = `/admin/program/progress/${id}?sessionNumber=${sessionNumber}&studentFormFilled=${studentFormFilled}`;
        }
      } else if (!sessionNumber && studentFormFilled) {
        url = `/admin/program/progress/${id}?studentFormFilled=${studentFormFilled}`;
      }
      return await axiosSettings.post(url);
    } catch (error) {
      throw Object(error);
    }
  }

  static async markFirstSessionComplete(programId, mentorshipId) {
    try {
      return await axiosSettings.post(
        `/admin/program/progress/firstSession/${programId}/${mentorshipId}/`
      );
    } catch (error) {
      throw Object(error);
    }
  }

  static async getProgramInfo(programId) {
    try {
      return await axiosSettings.get(`/programInfo/${programId}/`);
    } catch (error) {
      throw Object(error);
    }
  }

  static async saveProgramInfo(data) {
    try {
      return await axiosSettings.post(`/programInfo/`, data);
    } catch (error) {
      throw Object(error);
    }
  }
}
