import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentService } from "../../../services/appointment";
import { ProgressService } from "../../../services/progress";
import { Modal, Card } from "react-bootstrap";
import styled from "styled-components";
import { FormsService } from "../../../services/forms";
import { parseAndSortLoadedForm } from "../../forms/CreateForm/helper";
import FormParserModal from "../../forms/CreateForm/FormParserModal";
import CompletedModal from "../../common/CompletedModal";
import { ProgramService } from "../../../services";
import { ResourcesService } from "../../../services/resources";
import { NotifyError } from "../../../Helpers";
import { EmbarkLocationDetails } from "../../../Helpers/infoDetails";

export default function SupervisorDashboard() {
  const { t, i18n } = useTranslation("translation");
  const [isOpen, setIsOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [progress, setProgress] = useState({});
  const [isCompleted, setCompleted] = useState(false);
  const [programInfo, setProgramInfo] = useState(null);
  const [partner, setPartner] = useState({});
  const isEnglish = i18n?.resolvedLanguage === "en";

  const getAppointments = async () => {
    try {
      const res = await AppointmentService.getAppointment();
      if (res.status === 200) {
        setAppointments(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getProgress = async () => {
    try {
      const res = await ProgressService.getSupervisorProgress();
      if (res.status === 200) {
        setProgress(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPartner = async () => {
    try {
      const res = await ProgressService.getPartnerInfo();
      if (res.status === 200) {
        setPartner(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdate = () => {
    getAppointments();
    getProgress();
    getPartner();
  };

  useEffect(() => {
    onUpdate();
  }, []);

  useEffect(() => {
    if (progress?.programId) {
      getProgramDetails();
    }
  }, [progress]);

  const sortedProgress = progress?.sessionProgresses?.sort(
    (a, b) => a.sessionNumber - b.sessionNumber
  );

  async function getProgramDetails() {
    try {
      const res = await ProgramService.getProgramInfo(progress?.programId);
      if (res) {
        setProgramInfo(res?.data);
      }
    } catch (err) {
      setProgramInfo(null);
      console.log("error");
    }
  }

  useEffect(() => {
    if (progress && progress?.finalSurvey?.isAvailable) {
      setCompleted(true);
    }
  }, [progress]);

  return (
    <Wrapper>
      <div>
        <StyledTitle>
          {t("Welcome to the GDN Platform Reverse Mentoring Module")}
        </StyledTitle>
        <p>
          {isEnglish
            ? "Mentorship sessions are planned by your mentor. You need to wait for your mentor to send you an invitation. When your mentor sends you an invitation, an automatic Google Calendar invitation will also be sent to your email. In any case, you can reach out to your mentor through the contact information provided."
            : "Mentorluk görüşmeleri için seans planları mentiler tarafından yapılmaktadır. Öncelikle mentinizin size davet iletmesini beklemeniz gerekmektedir.  Mentiniz tarafından size davet iletildiğinde otomatik Google Calender daveti de e-postanıza iletilecektir. Herhangi bir durumda mentinize, iletişim bilgileri üzerinden ulaşabilirsiniz."}
        </p>
        <div className="mt-3">
          <p>
            <strong>{t("Program Name")}:</strong> {programInfo?.programName}
          </p>
          <p>
            <strong>{t("Project Name")}:</strong> {programInfo?.projectName}
          </p>
        </div>
        {partner?.email && (
          <>
            <p style={{ marginTop: 15, fontSize: 16 }}>
              <strong>{t("Menti Contact Details")}:</strong>
            </p>
            {partner?.firstname && partner?.lastname && (
              <p>
                <strong>{t("Name Surname")}:</strong>{" "}
                {`${partner?.firstname || ""} ${partner?.lastname || ""}`}
              </p>
            )}
            {partner?.email && (
              <p>
                <strong>E-posta:</strong> {partner?.email}
              </p>
            )}
            {partner?.phone && (
              <p>
                <strong>{t("Phone")}:</strong> {partner?.phone}
              </p>
            )}
          </>
        )}
        <button
          className="btn border-primary text-primary"
          onClick={() => setIsOpen(true)}
        >
          {t("Download Mentor Guide")}
        </button>
      </div>
      <div></div>
      <CompletedModal
        show={isCompleted}
        onCompleted={setCompleted}
        hasFinalForm={progress?.finalSurvey?.formId}
      />
      <DownloadModal
        show={isOpen}
        programInfo={programInfo}
        onHide={() => setIsOpen(false)}
      />
      {sortedProgress &&
        sortedProgress?.map((item) => {
          return (
            <SessionCard
              key={item?.sessionNumber}
              item={item}
              appointments={appointments}
              onUpdate={onUpdate}
            />
          );
        })}
    </Wrapper>
  );
}

const ordinalNumber = (i) => {
  const ordinals = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];
  return ordinals[i - 1] || `${i}th`;
};

const formatDate = (timestamp) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  return new Date(timestamp).toLocaleDateString(undefined, options);
};

const SessionCard = ({ item, appointments, onUpdate }) => {
  const { t } = useTranslation("translation");
  const [formData, setFormData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [formLanguages, setLanguages] = useState({
    isEnglish: true,
    isTurkish: true,
  });

  const sortedAppts = [...appointments].sort((a, b) => a.id - b.id);
  const currentSession = sortedAppts[item?.sessionNumber - 1];

  const showForm = async (id) => {
    const res = await FormsService.getFormById(id);
    if (res.status === 200) {
      const { isEnglish, isTurkish } = res["data"];
      const sortedElements = parseAndSortLoadedForm(res);
      setFormData(sortedElements);
      setIsOpen(true);
      setLanguages({ isEnglish, isTurkish });
    }
  };

  const submitForm = async (data) => {
    console.log(currentSession);
    const res = await FormsService.submitSupervisorForm({
      ...data,
      formId: Number(item?.formId),
      sessionId: Number(currentSession?.sessionId),
    });
    if (res.status === 200) {
      setIsOpen(false);
      onUpdate();
    }
  };
  const title = `${ordinalNumber(item?.sessionNumber)} Session`;
  return (
    <>
      <StyledCard
        className="custom-card position-static"
        hasSession={!!currentSession}
      >
        <Card.Body className="position-static">
          <InfoRow>
            <Title>{t(title)}</Title>
          </InfoRow>
          {currentSession && (
            <>
              <InfoRow>
                <Label>{t("Meeting Title")}:</Label>
                <Content>{currentSession.title}</Content>
              </InfoRow>
              <InfoRow>
                <Label>{t("Meeting Date")}:</Label>
                <Content>{formatDate(currentSession.start)}</Content>
              </InfoRow>
              <InfoRow>
                <Label>{t("Meeting Location")}:</Label>
                {currentSession.isOffSite ? (
                  <div>
                    {EmbarkLocationDetails} -{" "}
                    <a
                      className="cursor-pointer"
                      href={currentSession.location}
                      target="_blank"
                    >
                      {t("Click for Google Maps")}.
                    </a>{" "}
                  </div>
                ) : (
                  <a
                    className="cursor-pointer"
                    href={currentSession.location}
                    target="_blank"
                  >
                    {currentSession.location}
                  </a>
                )}
              </InfoRow>
            </>
          )}
          {item?.formId && (
            <InfoRow>
              <Label>{t("Form Filled")}:</Label>
              <Status isAvailable={item?.isFilled}>
                {item?.isFilled ? (
                  t("Yes")
                ) : item?.isAvailable ? (
                  <StyledButton onClick={() => showForm(item?.formId)}>
                    {t("Fill Form")}
                  </StyledButton>
                ) : (
                  t("No")
                )}
              </Status>
            </InfoRow>
          )}
          <InfoRow>
            <Label>{t("Meeting Completed")}:</Label>
            <Status isAvailable={item?.isAvailable}>
              {item?.isAvailable ? t("Yes") : t("No")}
            </Status>
          </InfoRow>
        </Card.Body>
      </StyledCard>
      <FormParserModal
        isOpen={isOpen}
        formLanguages={formLanguages}
        data={formData}
        onClose={() => {
          setIsOpen(false);
        }}
        onSubmit={(data) => {
          submitForm(data);
        }}
      />
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
`;

const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  opacity: ${(props) => (props.hasSession ? "1" : "0.5")};
  transition: opacity 0.3s ease-in-out;
`;

const InfoRow = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

const Title = styled.span`
  font-weight: bold;
  color: orange;
  margin-right: 10px;
`;

const Content = styled.span`
  color: #555;
`;

const Status = styled.span`
  color: ${(props) => (props.isAvailable ? "green" : "red")};
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const StyledTitle = styled.h3`
  @media (max-width: 600px) {
    margin-top: 100px;
  }
`;

function DownloadModal(props) {
  const { t } = useTranslation("translation");

  async function getDocumentUrl(name) {
    try {
      const res = await ResourcesService.readResourceLink(name);
      if (res) {
        return res?.data?.url;
      }
    } catch (err) {
      NotifyError("Document Error");
    }
  }

  const getFile = async (fileName) => {
    const name = await getDocumentUrl(fileName);
    props.onHide();
    window.open(name);
  };

  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton onClick={props.onHide}></Modal.Header>
      <Modal.Body className="text-center pd-y-20 pd-x-20">
        {t("Which language do you want to download the document")}?
      </Modal.Body>
      <Modal.Footer>
        {props?.programInfo?.supervisorEnDocument && (
          <a
            className="btn btn-sm btn-primary"
            onClick={() => getFile(props?.programInfo?.supervisorEnDocument)}
          >
            {t("Download English Document")}
          </a>
        )}
        {props?.programInfo?.supervisorTrDocument && (
          <a
            className="btn btn-sm btn-secondary"
            onClick={() => getFile(props?.programInfo?.supervisorTrDocument)}
          >
            {t("Download Turkish Document")}
          </a>
        )}
      </Modal.Footer>
    </Modal>
  );
}
